console.log("******************loading custom.js**********************")


$(document).ready(function() {
  	$('.btn').each(function() {
		if (this.href === window.location.href) {
			$(this).addClass('active-link');
		}
	});

	$('.btn').click(function() {
		$('.btn').removeClass('active-link'); // Remove active class from all links
		$(this).addClass('active-link'); // Add active class to clicked link
	});
});

document.addEventListener("DOMContentLoaded", function(){

	window.addEventListener('scroll', function() {
		
		if (window.scrollY > 1) {
			if(document.getElementById('navbar_top') != undefined && document.getElementById('navbar_top') != ""){

				document.getElementById('navbar_top').classList.add('fixed-top');
				document.getElementById('navbar_top').style.marginTop = '0' + 'px';
			
				// add padding top to show content behind navbar
				navbar_height = document.querySelector('.navbar').offsetHeight;
				document.body.style.paddingTop = navbar_height + 'px';
			}
		} else {
			if(document.getElementById('navbar_top') != undefined && document.getElementById('navbar_top') != "")

				document.getElementById('navbar_top').classList.remove('fixed-top');
					// remove padding top from body
				document.body.style.paddingTop = '0';
				document.getElementById('navbar_top').style.marginTop = '0' + 'px';

		} 
	});
}); 
window.addEventListener('DOMContentLoaded', (event) => {
	setTimeout(function () {
		console.log("Hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
		$('#aler').addClass("d-none");
		$('#note').addClass("d-none");}, 
	0)
	console.log('DOM fully loaded and parsed')

	

});