
$(function(){
  if(document.getElementById('change-csrf-button') != undefined && document.getElementById('change-csrf-button') != ""){
    document.getElementById('change-csrf-button').addEventListener('click', function() {

      fetch('/new_csrf_token') // Call your controller action

      .then(response => response.json())

      .then(data => {
        console.log(data)
        document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.csrf_token);
        
      });

    });
  }
  am4core.useTheme(am4themes_animated);
  var actPerMonth = undefined;
  var fishermanPerMonth = undefined;
  var distancePerMonth = undefined;
  
  if(window.location.pathname === '/'){
    const AUTH_TOKEN = encodeURIComponent(
      $("meta[name=csrf-token]").prop("content")
    );
    
    const SERVER = `/dashboard_data_stats?authenticity_token=${AUTH_TOKEN}`;
      
    fetch(SERVER, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
    .then((json) => {
      

      if (json && json.success) {
        var actPerMonth = json.activity_per_month
        var fishermanPerMonth = json.fisherman_per_month
        var distancePerMonth  = json.distance_per_month
        var chartdiv = am4core.create("chartdiv", am4charts.XYChart);
        chartdiv.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chartdiv.data = [];
        if(actPerMonth != undefined && actPerMonth != null && actPerMonth != ''){
            const activity_data = new Map(Object.entries(actPerMonth));
            for (let [key, value] of activity_data) {

              chartdiv.data.push( {
                country:  key ,
                visits: value
              })

            }
          


            var categoryAxis = chartdiv.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.grid.template.location = 0;

            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.minGridDistance = 5;
            categoryAxis.renderer.labels.template.rotation = -50;



            var valueAxis = chartdiv.yAxes.push(new am4charts.ValueAxis());
            // valueAxis.min = 0;
            // // valueAxis.max = 500;
            // valueAxis.strictMinMax = true;
            valueAxis.renderer.minGridDistance = 30;
            // // axis break
            // var axisBreak = valueAxis.axisBreaks.create();
            // axisBreak.startValue =1000000;
            // axisBreak.endValue = 22900;
            // axisBreak.breakSize = 0.005;

            // make break expand on hover
            // var hoverState = axisBreak.states.create("hover");
            // hoverState.properties.breakSize = 1;
            // hoverState.properties.opacity = 0.1;
            // hoverState.transitionDuration = 1500;

            // axisBreak.defaultState.transitionDuration = 500;
            /*
            // this is exactly the same, but with events
            axisBreak.events.on("over", () => {
              axisBreak.animate(
                [{ property: "breakSize", to: 1 }, { property: "opacity", to: 0.1 }],
                1500,
                am4core.ease.sinOut
              );
            });
            axisBreak.events.on("out", () => {
              axisBreak.animate(
                [{ property: "breakSize", to: 0.005 }, { property: "opacity", to: 1 }],
                500,
                am4core.ease.quadOut
              );
            });*/

            var series = chartdiv.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "country";
            series.dataFields.valueY = "visits";
            series.columns.template.tooltipText = "{valueY.value}";
            series.columns.template.tooltipY = 0;
            series.columns.template.strokeOpacity = 0;

            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
            series.columns.template.adapter.add("fill", (fill, target) => {
              return chartdiv.colors.getIndex(target.dataItem.index);
            });

            
        }

        if(fishermanPerMonth != undefined && fishermanPerMonth != null && fishermanPerMonth != ''){
          var chartdiv2 = am4core.create("chartdiv2", am4charts.XYChart);
          chartdiv2.hiddenState.properties.opacity = 0; // this creates initial fade-in

          chartdiv2.data = [];
          let fisher_data = new Map(Object.entries(fishermanPerMonth));
          for (let [key, value] of fisher_data) {

          chartdiv2.data.push( {
              country:  key ,
              visits: value
            })

          }

          var categoryAxis = chartdiv2.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.dataFields.category = "country";
          categoryAxis.renderer.minGridDistance = 5;
          categoryAxis.renderer.labels.template.rotation = -50;


          var valueAxis = chartdiv2.yAxes.push(new am4charts.ValueAxis());
          valueAxis.min = 0;
          // valueAxis.max = 500;
          // valueAxis.strictMinMax = true;
          valueAxis.renderer.minGridDistance = 30;
          // axis break
          // var axisBreak = valueAxis.axisBreaks.create();
          // axisBreak.startValue =1000000;
          // axisBreak.endValue = 22900;
          // axisBreak.breakSize = 0.005;

          // make break expand on hover
          // var hoverState = axisBreak.states.create("hover");
          // hoverState.properties.breakSize = 1;
          // hoverState.properties.opacity = 0.1;
          // hoverState.transitionDuration = 1500;

          // axisBreak.defaultState.transitionDuration = 500;
          /*
          // this is exactly the same, but with events
          axisBreak.events.on("over", () => {
            axisBreak.animate(
              [{ property: "breakSize", to: 1 }, { property: "opacity", to: 0.1 }],
              1500,
              am4core.ease.sinOut
            );
          });
          axisBreak.events.on("out", () => {
            axisBreak.animate(
              [{ property: "breakSize", to: 0.005 }, { property: "opacity", to: 1 }],
              500,
              am4core.ease.quadOut
            );
          });*/

          var series = chartdiv2.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "country";
          series.dataFields.valueY = "visits";
          series.columns.template.tooltipText = "{valueY.value}";
          series.columns.template.tooltipY = 0;
          series.columns.template.strokeOpacity = 0;

          // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
          series.columns.template.adapter.add("fill", (fill, target) => {
            return chartdiv2.colors.getIndex(target.dataItem.index);
          });

          
        }
        if(distancePerMonth != undefined && distancePerMonth != null && distancePerMonth != ''){
          var chartdiv3 = am4core.create("chartdiv3", am4charts.XYChart);
          chartdiv3.hiddenState.properties.opacity = 0; // this creates initial fade-in

          chartdiv3.data = [];
          let distance_data = new Map(Object.entries(distancePerMonth));
          for (let [key, value] of distance_data) {

            chartdiv3.data.push( {
              country:  key ,
              visits: value
            })

          }
          var categoryAxis = chartdiv3.xAxes.push(new am4charts.CategoryAxis());
          categoryAxis.renderer.grid.template.location = 0;
          categoryAxis.dataFields.category = "country";
          categoryAxis.renderer.minGridDistance = 5;
          categoryAxis.renderer.labels.template.rotation = -50;

          var valueAxis = chartdiv3.yAxes.push(new am4charts.ValueAxis());
          // valueAxis.min = 0;
          // // valueAxis.max = 100;
          // valueAxis.strictMinMax = true;
          valueAxis.renderer.minGridDistance = 30;
          // // axis break
          // var axisBreak = valueAxis.axisBreaks.create();
          // axisBreak.startValue =1000000;
          // axisBreak.endValue = 22900;
          // axisBreak.breakSize = 0.005;

          // make break expand on hover
          // var hoverState = axisBreak.states.create("hover");
          // hoverState.properties.breakSize = 1;
          // hoverState.properties.opacity = 0.1;
          // hoverState.transitionDuration = 1500;

          // axisBreak.defaultState.transitionDuration = 500;
          /*
          // this is exactly the same, but with events
          axisBreak.events.on("over", () => {
            axisBreak.animate(
              [{ property: "breakSize", to: 1 }, { property: "opacity", to: 0.1 }],
              1500,
              am4core.ease.sinOut
            );
          });
          axisBreak.events.on("out", () => {
            axisBreak.animate(
              [{ property: "breakSize", to: 0.005 }, { property: "opacity", to: 1 }],
              500,
              am4core.ease.quadOut
            );
          });*/

          var series = chartdiv3.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "country";
          series.dataFields.valueY = "visits";
          series.columns.template.tooltipText = "{valueY.value}";
          series.columns.template.tooltipY = 0;
          series.columns.template.strokeOpacity = 0;

          var series2 = chartdiv3.series.push(new am4charts.LineSeries());
          series2.name = "Units";
          series2.stroke = am4core.color("green");
          series2.strokeWidth = 3;
          series2.dataFields.valueY = "visits";
          series2.dataFields.categoryX = "country";

          // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
          series.columns.template.adapter.add("fill", (fill, target) => {
            return chartdiv3.colors.getIndex(target.dataItem.index);
          });
        }                                                          
      } else{
        console.log('No Data Present')
        var actPerMonth = json.activity_per_month
        var fishermanPerMonth = json.fisherman_per_month
        var distancePerMonth  = json.distance_per_month
      }
      
    });
  }
})

$('.filter-toggle-btn').click(function(){
    $('.filter-block').toggle('smooth');
})

function populateBlockFilter(elem){
  const data = `${$redis.get("filter_data")}`
  const selectedData = data[elem]
  const blockData = selectedData.blocks
  const villageData = selectedData.village
  var blockHtml = '<option value="">Select Block</option>'
  var villageHtml = '<option value="">Select Village</option>'
  
  $.each(blockData,function(dist, val) { 
    blockHtml += '<option value="' + val+ '">' + val + '</option>';  });
  
  $('#q_user_block_cont').html(blockHtml);

    $.each(villageData,function(dist, val) { 
    villageHtml += '<option value="' + val+ '">' + val + '</option>';  });

  $('#q_user_village_cont').html(villageHtml);
}

function populateVillageFilter(elem){
    const data = `${$redis.get("filter_data")}`
    const selectedDistData = $('#q_user_district_cont').val()
    const selectedData = data[selectedDistData]
    const villageData = data[selectedDistData][elem].village
    var villageHtml = '<option value="">Select Village</option>'

    $('#q_user_village_cont').empty()
    
    $.each(villageData,function(dist, val) { 
      villageHtml += '<option value="' + val+ '">' + val + '</option>';  });

    $('#q_user_village_cont').html(villageHtml);
}


// $(function(){
//   $('#q_name_cont').select2({
//     minimumInputLength:4,
//     placeholder: 'Select Fisherman',
//      width: '100%',
//     ajax: {
//       url: '/users/autocomplete',
//       data: function (params) {
//         var query = {
//           q: params.term,
//           type: 'name'
//         }
//         return query;
//       },
//       processResults: function (data) {
//         console.log(data.data)
//         // Transforms the top-level key of the response object from 'items' to 'results'
//         return {
//           results: data.data
//         };
//       }
//     }

//   });


//   $('#q_phone_number_cont').select2({
//       minimumInputLength:4,
//       placeholder: 'Select Phone Number',
//       width: '100%',
//       ajax: {
//         url: '/users/autocomplete',
//         data: function (params) {
//           var query = {
//             q: params.term,
//             type: 'phone_number'
//           }
//           return query;
//         },
//         processResults: function (data) {
//           console.log(data.data)
//           // Transforms the top-level key of the response object from 'items' to 'results'
//           return {
//             results: data.data
//           };
//         }
//       }
//     });

//     $('#q_block_cont').select2({
//       minimumInputLength:4,
//       placeholder: 'Select block',
//       width: '100%',
//       ajax: {
//         url: '/users/autocomplete',
//         data: function (params) {
//           var query = {
//             q: params.term,
//             type: 'block'
//           }
//           return query;
//         },
//         processResults: function (data) {
//           console.log(data.data)
//           // Transforms the top-level key of the response object from 'items' to 'results'
//           return {
//             results: data.data
//           };
//         }
//       }
//     });

    // $('#q_landing_site_cont').select2({
    //   minimumInputLength:4,
    //   placeholder: 'Select Landing Site',
    //   width: '100%',
    //   ajax: {
    //     url: '/users/autocomplete',
    //     data: function (params) {
    //       var query = {
    //         q: params.term,
    //         type: 'landing_site'
    //       }
    //       return query;
    //     },
    //     processResults: function (data) {
    //       console.log(data.data)
    //       // Transforms the top-level key of the response object from 'items' to 'results'
    //       return {
    //         results: data.data
    //       };
    //     }
    //   }
//     // });
  

  
//   // var ms = $('#q_name_cont').magicSuggest({
//   //   minChars: 4,
//   //   hideTrigger: true,
//   //   maxDropHeight: 100,
//   //   placeholder: 'Make a selection',
//   //   selectFirst: true,
//   //   allowFreeEntries: false,
//   //   allowDuplicates: false,
//   //   maxSelection: 1,
//   //   editable: true,
//   // })
//   //  $(ms).on('keyup', function(e, m, v) {
//   //       var val = m.getRawValue()
//   //       if (val.length > 4){
//   //       $.get(`/users/autocomplete?q=${val}`, function(data, status){
//   //         ms.setData(data.data)
//   //         console.log(data.data)
//   //       });
//   //       }
//   //   })

//   // var mss = $('#q_phone_number_cont').magicSuggest({
//   //   minChars: 4,
//   //   hideTrigger: true,
//   //   maxDropHeight: 100,
//   //   placeholder: 'Make a selection',
//   //   selectFirst: true,
//   //   allowFreeEntries: false,
//   //   allowDuplicates: false,
//   //   maxSelection: 1,
//   // })
//   // $(mss).on('keyup', function(e, m, v) {
//   //     var val = m.getRawValue()
//   //     if (val.length > 4){
//   //     $.get(`/users/autocomplete?q=${val}`, function(data, status){
//   //       mss.setData(data.data)
//   //       console.log(data.data)

//   //     });
//   //     }
//   // })
// })
$(document).ready(function() {
  console.log("<<<<<<<<", 'Map is Present!' ,">>>>>>>>>>>")
  if(document.getElementById("map") != undefined ){
    $("button").on("click",function(){
    $("tr").each(function(){
    $(this).removeClass("marked");
    });
    $(this).closest("tr").addClass("marked");
    });
    url = window.location.href;
    userId = url.substring(url.lastIndexOf('/') + 1);
    //global variables for the  markers---------------------------------------------------------------------------------------------
    var id, marker1, marker2;
    var markers = [];
    var item, value1;
    var value2 = 0;


    //end----------------------------------------------------------------------------------------------------------------------------
    console.log("MAP is not loaded yet")

    //this code is to display the map on the screen----------------------------------------------------------------------------------
    mapboxgl.accessToken = "pk.eyJ1IjoicmFqbmlzaDkxMyIsImEiOiJja3l3cGo3bXAwYXA4MnFxaTZ3ano1eXprIn0.2r0BUrcnXXeZQzqrdP_YHQ";
    var coordinates = { lat: 28.7041, lng: 77.1025 };
    var map = new mapboxgl.Map({
      center: coordinates,
      zoom: 1, // starting zoom
      container: "map",
      gestureHandling: "cooperative",
      style: "mapbox://styles/mapbox/streets-v11",
    });
    console.log('<<<<<',map,'>>>>')

    map.addControl(new mapboxgl.NavigationControl());
    console.log("MAP is loaded")
    //drawing markers on button click with ajax call for path ---------------------------------------------------------------------------------------------------
    $(document).ready(function(){
      mapintial_postion()
    });

    $(".dateButton").click(function (item) {
    //   show_journey_datails(item)
      var id = item.target.getAttribute("id");
      console.log("ID ========>",id);
      if(id != undefined && id != null && id != ''){
        $.ajax({
          url: "/journeyapi/" + id,
          success: function (json) {
            console.log(json);
            removemarker();
            console.log("remove marker called from ajax");
            drawMarker(json);
            console.log("draw called from ajax");
          },
        });
        return false;
      }
    });
    //end----------------------------------------------------------------------------------------------------------
    //drawing initial position on first time map load---------------------------------------------------------------------
      function mapintial_postion(){
        const AUTH_TOKEN = encodeURIComponent(
          $("meta[name=csrf-token]").prop("content")
        );
          
        const SERVER = `/journey_json?authenticity_token=${AUTH_TOKEN}&user_id=${userId}`;
          
        fetch(SERVER, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
        })
        .then((response) => response.json())
        .catch((error) => console.log(error))
        .then((json) => {
          

          if (json && json.success) {

            var journeys = JSON.parse(json.journies)
            var journey = journeys[0]
            var data = {path: journey.path , landingPoint: journey.landingPoint, fishingPoint: journey.fishingPoint}
            console.log(data);
            setTimeout(() => drawMarker(data), 2000);
            console.log("draw marker called from intializer")
          }
        })
      }
      //end------------------------------------------------------------------------------------------------------------

      //remove marker function ----------------------------------------------------------------------------------------------
      function removemarker()
      {
        console.log("remove images called")
        map.removeImage('fish');
        map.removeImage('boat');
        map.removeLayer('route');
        map.removeSource('route');
        map.removeLayer('points');
        map.removeSource('points');
      }

    //end-----------------------------------------------------------------------------------------------------------------


      //draw marker function ------------------------------------------------------------------------------------------------
      function drawMarker(json) {
        console.log(json)
        console.log(json.path[0])
        console.log(json.path[0]["lat"])
        console.log(json.path[0]["lon"])
        if (json.path.length > 1){
          var line = turf.lineString(json.path.map((latLon) => [latLon.lon, latLon.lat]));
          var bbox = turf.bbox(line);

        } else{

          bbox=[]

          bbox[0]=json.fishingPoint[0]
          bbox[1]=json.fishingPoint[1]
          bbox[2]=json.landingPoint[0]
          bbox[3]=json.landingPoint[1]

            map.fitBounds(
            [
              [bbox[0], bbox[1]],
              [bbox[2], bbox[3]]
            ],
            {
              padding: { top: 100, bottom: 100, left: 50, right: 50 },
            });

        }




    console.log("drawmarker drawing paths");

    imagecord = json.fishingPoint
    boatcord = [json.path[0]["lon"],json.path[0]["lat"]]
    // boatcord = json.landingPoint
    //  console.log( "landing point")
    // console.log( boatcord)
    const images = {
      'fish': "/assets/fishHunt-03c00914f2da586391e73dc26691bcd8358a4752f1822e634d3542a5968b80fb.png",
      'boat': "/assets/sailingBoat-9f2c454dd36946d3f20f50b232eb8abb0b5715bab463f16a2e2132c34d54cca7.png"
    }
        loadImages(images, (loadedImages) => {
          map.addImage('fish',  loadedImages['fish']);
          map.addImage('boat',  loadedImages['boat']);

          map.addSource('points', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [{
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates':  imagecord
                },
                'properties': {
                  'icon': 'fish'
                }
              },
              {
                'type': 'Feature',
                'geometry': {
                  'type': 'Point',
                  'coordinates':  boatcord
                },
                'properties': {
                  'icon': 'boat'
                }
              }]
            }
          });
        map.addLayer({
          'id': 'points',
          'type': 'symbol',
          'source': 'points',
          'layout': {
            'icon-image': ['get', 'icon'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-size': 0.10
          }
        });


        });




      // markers.push(marker1);
      // markers.push(marker2);

      map.addSource('route', {
    'type': 'geojson',
    'data': {
    'type': 'Feature',
    'properties': {},
    'geometry': {
      'type': 'LineString',
      'coordinates': json.path.map((latLon) => [latLon.lon, latLon.lat])
    }
    }
    });

    map.addLayer({
    'id': 'route',
    'type': 'line',
    'source': 'route',
    'layout': {
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-color': 'green',
      'line-width': 4,
      'line-dasharray': [2, 1]
    }
    });

    if (json.path.length > 1)
    {
      map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],

      ],
      {
        padding: { top: 100, bottom: 100, left: 50, right: 50 },
      });
    }


    }
    //end------------------------------------------------------------------------------------------------------

      function show_journey_datails(item)
    {
        var value = item.target.getAttribute("value");
        var d = parseFloat(value)
        value1 = d;
        value2 = 0;
        document.getElementById("distance").innerHTML = value1 + " " +"Km" ;
    }

    function loadImages(urls, callback) {
      const results = {};
      for (const name in urls) {
      map.loadImage(urls[name], makeCallback(name));
    }

    function makeCallback(name) {
      return (err, image) => {
      results[name] = err ? null : image;

    // if all images are loaded, call the callback
    if (Object.keys(results).length === Object.keys(urls).length) {
      callback(results);
    }
    };
      }
      }
  }

  
})
